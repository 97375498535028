import _ from 'lodash';
import './FilterBar.scss';

export default function FilterBar({ children, className }) {
  const renderChildren = () => {
    if (_.isArray(children)) {
      return children.map((child, index) => (
        <div key={index} className="filter-chip-container">
          {child}
        </div>
      ));
    } else {
      return (
        <div className="filter-chip-container">
          {children}
        </div>
      );
    }
  };

  return (
    <div className={`filter-bar ${className ? className : ''}`}>
      {renderChildren()}
    </div>
  );
}
