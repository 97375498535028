import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { WizardDialog } from '../';
import LotsGaragesTable from './LotsGaragesTable';
import { getLots } from '../../redux/slices/lots.slice';
import { clearAllFilters } from '../../redux/slices/filter.slice';

const lotsListLabelKeys = [
  { label: 'Lot / Garage', key: 'displayName', sortable: true },
  { label: 'Organizations', key: 'organizations', sortable: true }
];

export default function LotsGarages({ actions }) {
  const dispatch = useDispatch();
  const wizardVisible = useSelector(state => state.wizard.visible);
  const organizations = useSelector(state => state.organizations.relationships);
  const wizardContent = useSelector(state => state.wizard.data);
  const wizardTitle = useSelector(state => state.wizard.title);
  const lots = useSelector(state => state.lots.data);
  const filteredData = useSelector(state => state.filter.lotsFilteredData);
  const isLoading = useSelector((state => state.lots.isLoading || state.organizations.isLoading));

  useEffect(() => {
    dispatch(clearAllFilters());
    dispatch(getLots());
  }, [dispatch]);

  const lotsWithParentOrg = lots?.map(lot => {
    const newLot = { ...lot };

    newLot.organizations = newLot?.organizations?.map(org => {
      const newOrg = {
        ...org
      };

      newOrg.parentKey = organizations?.find(x => x.childKey === newOrg.partyKey) && organizations?.find(x => x.childKey === newOrg.partyKey).parentKey;

      return newOrg;
    }) || [];

    return newLot;
  });

  const filterGuard = () => {
    if (!filteredData) {
      return false;
    } else if (filteredData?.length > 0 && 'displayName' in filteredData[0]) {
      return true;
    } else if (filteredData?.length === 0) {
      return true;
    }
  };

  return (
    <>
      <div>
        <LotsGaragesTable
          title="Lots/Garages"
          labelKeys={lotsListLabelKeys}
          isLoading={isLoading}
          data={filterGuard() ? filteredData : lotsWithParentOrg}
          actions={actions}
        />
      </div>
      <WizardDialog
        visible={wizardVisible}
        onHide={actions.hideStepWizard}
        data={wizardContent}
        title={wizardTitle}
      />
      <LoadingSpinner
        show={isLoading}
        delay={650}
      />
    </>
  );
}
