import StepWizard from 'react-step-wizard';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { useSelector } from 'react-redux';
import { WizardDialog, TreeContainer, TreePicker } from '../';
import './OrganizationsTree.scss';

export default function OrganizationsTree({ actions }) {
  const wizardData = useSelector(state => state.wizard.data);
  const wizardTitle = useSelector(state => state.wizard.title);
  const wizardVisible = useSelector(state => state.wizard.visible);
  const isLoading = useSelector(state => state.organizations.isLoading);

  const orgActions = {
    hideStepWizard: actions.hideStepWizard,
    showStepWizard: actions.showStepWizard,
    hideDeactivateDialog: actions.hideDeactivateDialog,
    showDeactivateDialog: actions.showDeactivateDialog
  };

  const TreeSteps = [
    <TreePicker
      key={1}
    />,
    <TreeContainer
      key={2}
      dashboard
    />
  ];

  return (
    <>
      <StepWizard
        isLazyMount
        id="wizard"
        children={TreeSteps}
      />
      <WizardDialog
        visible={wizardVisible}
        onHide={orgActions.hideStepWizard}
        data={wizardData}
        title={wizardTitle}
      />
      <LoadingSpinner
        show={isLoading}
        delay={650}
      />
    </>
  );
}
