import { createSlice } from '@reduxjs/toolkit';

export const deactivateDialogSlice = createSlice({
  name: 'deactivateDialog',
  initialState: {
    data: null,
    visible: false
  },
  reducers: {
    deactivateToggle: (state, action) => {
      state.data = action.payload.data;
      state.visible = action.payload.visible;
    }
  }
});

export const { deactivateToggle } = deactivateDialogSlice.actions;

export default deactivateDialogSlice.reducer;
