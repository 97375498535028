import { FontIcon, DropdownMenu, MenuItem, BELOW_INNER_RIGHT_ANCHOR } from 'react-md';
import { checkForAccess } from '../../utils';

export default function LotsGaragesTableMenu({ actions, title, index, item, resetSort }) {
  const inactive = new Date(item.deactivation) < new Date();
  const canUpdate = checkForAccess('suite.lots.garages.update', 'WRITE');
  const canDeactivate = checkForAccess('suite.lots.garages.deactivate', 'WRITE');

  if (!canUpdate && !canDeactivate) {
    return null;
  }

  return (
    <DropdownMenu
      id={`table-menu-button-${index}`}
      className="table-menu-button"
      buttonType="icon"
      anchor={BELOW_INNER_RIGHT_ANCHOR}
      buttonChildren={<FontIcon>more_vert</FontIcon>}
    >
      {inactive && canDeactivate ?
        <MenuItem
          key={1}
          id={`people-menu-reactivate-${index}`}
          children="Reactivate"
          leftAddon={<FontIcon>person_add</FontIcon>}
          onClick={() => actions.showReactivateDialog({ data: item, title, action: actions.reactivateUser })}
        />
        :
        <>
          {canUpdate &&
            <MenuItem
              key={1}
              id={`lot-menu-edit-${index}`}
              primaryText="Edit"
              leftAddon={<FontIcon>edit</FontIcon>}
              onClick={() => {
                resetSort();
                actions.showStepWizard({ data: item, title, edit: true });
              }}
            />
          }
        </>
      }
    </DropdownMenu>
  );
}
