import { createRoot } from 'react-dom/client';
import { Configuration, MessageQueue } from 'react-md';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { App } from './components';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import './index.scss';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_CORE_USER_API_URL}/graphql`,
  credentials: 'include'
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Configuration>
        <BrowserRouter>
          <MessageQueue id="app-snackbar">
            <App />
          </MessageQueue>
        </BrowserRouter>
      </Configuration>
    </Provider>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
