import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchIcon } from '../../assets/react-icons/';
import { setSearchTerm, clearSearchTerm } from '../../redux/slices/filter.slice';
import { useDebounce } from '../../hooks';
import './SearchInput.scss';

export default function SearchInput({ id, handleSearch }) {
  const dispatch = useDispatch();
  const searchTerm = useSelector(state => state.filter.searchTerm);
  const debouncedSearch = useDebounce((input) => afterChange(input), 1000);

  useEffect(() => {
    dispatch(clearSearchTerm());
  }, [dispatch]);

  const change = (value) => {
    dispatch(setSearchTerm(value));
    debouncedSearch(value);
  };

  function afterChange(value) {
    handleSearch(value);
  }

  return (
    <div className="search-input-container" >
      <input
        id={id}
        type="text"
        onChange={(e) => change(e.target.value)}
        placeholder="Search by keyword"
        value={searchTerm}
      />
      <span>
        <SearchIcon color="rgba(0,0,0,.6)" width={24} />
      </span>
    </div>
  );
}
