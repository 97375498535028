import { useRef, useState, useEffect, useCallback } from 'react';
import { ArrowBackSVGIcon, ArrowUpwardSVGIcon, ArrowDownwardSVGIcon, ArrowForwardSVGIcon, Button, TextIconSpacing } from 'react-md';
import D3Tree from 'react-d3-tree';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from '../';
import { clearSearchTerm } from '../../redux/slices/filter.slice';
import TreeNode from './TreeNode';
import './TreeContainer.scss';

export default function TreeContainer({ previousStep, dashboard }) {
  const dispatch = useDispatch();
  const organizationTree = useSelector(state => state.tree.organizationTree);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const ref = useRef();

  const callbackReset = useCallback(() => {
    const dimensions = ref?.current?.getBoundingClientRect();
    setTranslateY(dimensions ? dimensions.height / 5 : 500);
    setTranslateX(dimensions ? dimensions.width / 2 : 500);
  }, [ref]);

  useEffect(() => {
    setTimeout(() => {
      callbackReset();
    }, 100);

  }, [callbackReset]);

  const goBack = () => {
    dispatch(clearSearchTerm());
    previousStep();
  };

  const navigateDown = () => {
    setTranslateY(translateY + 300);
  };

  const navigateUp = () => {
    setTranslateY(translateY - 300);
  };

  const navigateRight = () => {
    setTranslateX(translateX + 300);
  };

  const navigateLeft = () => {
    setTranslateX(translateX - 300);
  };

  function updateNode(e) {
    setTranslateX(e.translate.x);
    setTranslateY(e.translate.y);
  }

  const renderForeignObjectNode = ({
    foreignObjectProps,
    nodeDatum,
    toggleNode
  }) => {
    const isGroupNode = nodeDatum.type === 'FOLDER';
    let objectProps;

    if (isGroupNode) {
      objectProps = {
        ...foreignObjectProps,
        height: 45,
        y: 0
      };
    }

    const finalProps = objectProps ? objectProps : foreignObjectProps;

    return (
      <foreignObject {...finalProps}>
        <TreeNode nodeDatum={nodeDatum} toggleNode={toggleNode} />
      </foreignObject>
    );
  };

  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -100, y: -10 };

  return (
    <>
      {dashboard && (
        <Button
          id="dialog-back-button"
          onClick={goBack}
          className="back-button"
        >
          <TextIconSpacing icon={<ArrowBackSVGIcon />}>
            choose a different organization
          </TextIconSpacing>
        </Button>
      )}
      <div className="tree-controls-container">
        <Button
          buttonType="icon"
          onClick={navigateLeft}
          theme="clear"
        >
          <ArrowBackSVGIcon />
        </Button>
        <Button
          buttonType="icon"
          onClick={navigateUp}
          theme="clear"
        >
          <ArrowUpwardSVGIcon />
        </Button>
        <Button
          buttonType="icon"
          onClick={navigateDown}
          theme="clear"
        >
          <ArrowDownwardSVGIcon />
        </Button>
        <Button
          buttonType="icon"
          onClick={navigateRight}
          theme="clear"
        >
          <ArrowForwardSVGIcon />
        </Button>

        <Button
          theme="primary"
          onClick={callbackReset}
        >
          Re-center
        </Button>
      </div>
      {dashboard && (
        <SearchInput
          id="organizationTree"
          handleSearch={() => true}
        />
      )}
      <br />
      <div className={!dashboard ? 'no-dashboard' : ''} ref={ref}>
        <D3Tree
          data={organizationTree}
          translate={{ x: translateX, y: translateY }}
          enableLegacyTransition
          transitionDuration={1000}
          orientation="vertical"
          initialDepth={0}
          onUpdate={(e) => updateNode(e)}
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
          separation={{ siblings: 1.4, nonSiblings: 1.5 }}
          nodeSize={nodeSize}
        />
      </div>
    </>
  );
};
