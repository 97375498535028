import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, deactivateUser } from '../../redux/slices/users.slice';
import { getOrganizations } from '../../redux/slices/organizations.slice';
import { clearAllFilters } from '../../redux/slices/filter.slice';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { WizardDialog, DeactivateModal, ReactivateModal } from '../';
import PeopleTable from './PeopleTable';

const peopleListLabelKeys = [
  { label: 'Name', key: 'displayName', sortable: true },
  { label: 'Email', key: 'contactString', sortable: true },
  { label: 'Role', key: 'role', sortable: true },
  { label: 'Organizations', key: 'organization', sortable: false },
  { label: 'Invitations', key: 'invites', sortable: true },
  { label: 'Registered', key: 'registered', sortable: true }
];

export default function People({ actions }) {
  const dispatch = useDispatch();
  const wizardVisible = useSelector(state => state.wizard.visible);
  const wizardContent = useSelector(state => state.wizard.data);
  const wizardTitle = useSelector(state => state.wizard.title);
  const filteredData = useSelector(state => state.filter.peopleFilteredData);
  const team = useSelector(state => state.users.data);
  const isLoading = useSelector(state => state.users.isLoading || state.organizations.isLoading);
  const { hideStepWizard } = actions;

  useEffect(() => {
    dispatch(clearAllFilters());
    dispatch(getOrganizations());
    dispatch(getUsers());

    return () => hideStepWizard();
  }, [hideStepWizard, dispatch]);

  const teamActions = {
    ...actions,
    deactivateUser
  };

  const filterGuard = () => {
    if (!filteredData) {
      return false;

    } else if (filteredData?.length > 0 && 'contactString' in filteredData[0]) {
      return true;

    } else if (filteredData?.length === 0) {
      return true;
    }
  };

  return (
    <>
      <div>
        <PeopleTable
          title="People"
          labelKeys={peopleListLabelKeys}
          isLoading={isLoading}
          data={filterGuard() ? filteredData : team}
          actions={teamActions}
        />
      </div>
      <WizardDialog
        data={wizardContent}
        visible={wizardVisible}
        onHide={teamActions.hideStepWizard}
        title={wizardTitle}
      />
      <DeactivateModal
        type="People"
      />
      <ReactivateModal />
      <LoadingSpinner
        show={isLoading}
        delay={650}
      />
    </>
  );
}
