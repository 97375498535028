import { createSlice } from '@reduxjs/toolkit';
import { createFeatureAccess, getAuthUser } from '../../utils';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    authUser: getAuthUser(),
    featureAccess: createFeatureAccess()
  },
  reducers: {
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
      state.featureAccess = createFeatureAccess();
    }
  }
});

export const { setAuthUser } = sessionSlice.actions;

export default sessionSlice.reducer;
