import './NoTableData.scss';

export default function NoTableData() {
  return (
    <div className="no-data-container">
      <p className="big-question">?</p>
      <p className="no-data-message">No data matches<br />these parameters</p>
    </div>
  );
}
