import { FontIcon, DropdownMenu, MenuItem, BELOW_INNER_RIGHT_ANCHOR } from 'react-md';
import { checkForAccess } from '../../utils';

export default function PeopleTableMenu({ actions, title, index, item, resetSort }) {
  const inactive = new Date(item.deactivation) < new Date();
  const canUpdate = checkForAccess('suite.users.persons.update', 'WRITE');
  const canInvite = checkForAccess('suite.users.persons.invite', 'WRITE');
  const canDeactivate = checkForAccess('suite.users.persons.deactivate', 'WRITE');

  if (!canInvite && !canUpdate && !canDeactivate) {
    return null;
  }

  return (
    <DropdownMenu
      id={`table-menu-button-${index}`}
      className="table-menu-button"
      buttonType="icon"
      anchor={BELOW_INNER_RIGHT_ANCHOR}
      buttonChildren={<FontIcon>more_vert</FontIcon>}
    >
      {inactive && canDeactivate ?
        <MenuItem
          key={1}
          id={`people-menu-reactivate-${index}`}
          children="Reactivate"
          leftAddon={<FontIcon>person_add</FontIcon>}
          onClick={() => actions.showReactivateDialog({ data: item, title, action: actions.reactivateUser })}
        />
        :
        <>
          {canInvite &&
            <MenuItem
              key={1}
              id={`people-menu-send-email-${index}`}
              disabled={item.relationships === null}
              children="Send Email"
              leftAddon={<FontIcon>send</FontIcon>}
              onClick={() => actions.showStepWizard({ data: { ...item, email: true }, title: 'sendEmail', edit: true })}
            />
          }
          {canUpdate &&
            <MenuItem
              key={2}
              id={`people-menu-edit-${index}`}
              children="Edit"
              leftAddon={<FontIcon>edit</FontIcon>}
              onClick={
                () => {
                  resetSort();
                  actions.showStepWizard({ data: item, title, edit: true });
                }
              }
            />
          }
          {canDeactivate &&
            <MenuItem
              key={3}
              id={`people-menu-deactivate-${index}`}
              children="Deactivate"
              leftAddon={<FontIcon>block</FontIcon>}
              onClick={() => actions.showDeactivateDialog({ data: item, title, action: actions.deactivateUser })}
            />
          }
        </>
      }
    </DropdownMenu>
  );
}
