import { FontIcon } from 'react-md';
import './TagsContainer.scss';

const TagContainer = props => {
  const { tags = [], handleExisting, defaultValue } = props;
  return (
    <div className="full-container">
      <div className="tag-container">
        {tags.length > 0 ? tags.map(tag => (
          <div className="label-container" key={`eO-${tag.value}`}>
            <div className="label-display-name">
              <div className={`label-tag ${tag.status}-label`}>{tag.label}</div>
              {tag.status !== 'existing' && (
                <div className={`flag ${tag.status}`}>{tag.status === 'add' ? 'new' : 'to remove'}</div>)}
            </div>
            <button
              className="label-button"
              onClick={() => handleExisting(tag)}
            >
              <FontIcon className="tag-action-icon">{tag.status === 'remove' ? 'replay' : 'close'}</FontIcon>
            </button>
          </div>
        )) : (
          <p>{defaultValue}</p>
        )}
      </div>
    </div>
  );
};

export default TagContainer;
