import { createSlice } from '@reduxjs/toolkit';
import { create } from 'axios';

const ajax = create({
  contentType: 'application/json',
  baseURL: process.env.REACT_APP_CORE_USER_API_URL
});

ajax.defaults.withCredentials = true;

export const operatorsSlice = createSlice({
  name: 'operators',
  initialState: {
    data: [],
    allOperators: [],
    suggestedOperators: [],
    userOperators: [],
    isLoading: false,
    isError: false
  },
  reducers: {
    getOperatorsPending: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    getOperatorsRejected: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    getOperatorsSuccess: (state, action) => {
      state.allOperators = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    getSuggestedOperatorsPending: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    getSuggestedOperatorsRejected: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    getSuggestedOperatorsSuccess: (state, action) => {
      state.suggestedOperators = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    getUserOperatorsPending: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    getUserOperatorsRejected: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    getUserOperatorsSuccess: (state, action) => {
      state.userOperators = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    postOperatorsPending: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    postOperatorsRejected: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    postOperatorsSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    resetOperatorData: (state) => {
      state.data = [];
      state.allOperators = [];
      state.suggestedOperators = [];
      state.userOperators = [];
      state.isLoading = false;
      state.isError = false;
    }
  }
});

export default operatorsSlice.reducer;

export const {
  getOperatorsPending,
  getOperatorsRejected,
  getOperatorsSuccess,
  getSuggestedOperatorsPending,
  getSuggestedOperatorsRejected,
  getSuggestedOperatorsSuccess,
  getUserOperatorsPending,
  getUserOperatorsRejected,
  getUserOperatorsSuccess,
  postOperatorsPending,
  postOperatorsRejected,
  postOperatorsSuccess,
  resetOperatorData
} = operatorsSlice.actions;

export const getOperators = () => dispatch => {
  dispatch(getOperatorsPending());

  ajax.get('/operators')
    .then(resp => {
      dispatch(getOperatorsSuccess(resp.data.operators));
    })
    .catch(error => {
      dispatch(getOperatorsRejected(error));
    });
};

export const getSuggestedOperators = externalKey => dispatch => {
  dispatch(getSuggestedOperatorsPending());

  ajax.get(`/persons/${externalKey}/operators?action=suggest`)
    .then(resp => {
      dispatch(getSuggestedOperatorsSuccess(resp.data.operators));
    })
    .catch(error => {
      dispatch(getSuggestedOperatorsRejected(error));
    });
};

export const postOperators = (externalKey, operatorLegacyKeys, addMessage) => dispatch => {
  dispatch(postOperatorsPending());

  ajax.post(`/persons/${externalKey}/operators`, { operatorLegacyKeys })
    .then(resp => {
      dispatch(postOperatorsSuccess(resp.data.message));
    })
    .catch(error => {
      dispatch(postOperatorsRejected(error));
      addMessage({ children: 'Something went wrong adding operators.', action: 'dismiss' });
    });
};

export const deleteOperators = (externalKey, operatorLegacyKeys, addMessage) => dispatch => {
  ajax.delete(`/persons/${externalKey}/operators`, { data: { operatorLegacyKeys } })
    .catch(() => {
      addMessage({ children: 'Something went wrong removing operators.', action: 'dismiss' });
    });
};

export const getUserOperators = externalKey => dispatch => {
  dispatch(getUserOperatorsPending());

  ajax.get(`/persons/${externalKey}/operators`)
    .then(resp => {
      dispatch(getUserOperatorsSuccess(resp.data.operators));
    })
    .catch(error => {
      dispatch(getUserOperatorsRejected(error));
    });
};

export const clearOperatorData = () => dispatch => {
  dispatch(resetOperatorData());
};
