import { createSlice } from '@reduxjs/toolkit';

export const reactivateDialogSlice = createSlice({
  name: 'reactivateDialog',
  initialState: {
    data: null,
    visible: false
  },
  reducers: {
    reactivateToggle: (state, action) => {
      state.data = action.payload.data;
      state.visible = action.payload.visible;
    }
  }
});

export const { reactivateToggle } = reactivateDialogSlice.actions;

export default reactivateDialogSlice.reducer;
