import { useState, useEffect, useMemo, useCallback } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, TextField, ArrowBackSVGIcon, AutoComplete } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBar } from '../../';
import { getLandmarks } from '../../../redux/slices/organizations.slice';

export default function LandmarkStep({
  data,
  editData,
  actions,
  hideStepWizard,
  title,
  previousStep,
  currentStep
}) {
  const dispatch = useDispatch();
  const [landmarkName, setLandmarkName] = useState('');
  const [autoCompleteKey, setAutoCompleteKey] = useState('');
  const landmarks = useSelector(state => state.organizations.landmarks);
  const { update, confirmOrg } = actions;

  useEffect(() => {
    if (data?.childName) {
      setLandmarkName(data.childName);
    }

    if (!landmarks || landmarks.length < 1) {
      dispatch(getLandmarks());
    }
  }, [data, landmarks, dispatch]);

  function handleNameChange(value) {
    setLandmarkName(value);
    setAutoCompleteKey('');

    update({ landmarkName: value, autoCompleteKey: '' });
  }

  const onAutoComplete = useCallback(({ value }) => {
    const landmark = landmarks.filter(point => point.name === value);
    const dupeOrgRelations = value && landmarks.filter(x => x.id === landmark[0] && landmark[0].id);

    if (landmark) {
      setLandmarkName(landmark[0].name);
      setAutoCompleteKey(landmark[0].id);

      update({ landmarkName: landmark[0].name, landmarkId: landmark[0].id, dupeOrgs: dupeOrgRelations });
    }
  }, [landmarks, update]);

  const handleSubmit = async () => {
    confirmOrg(false, editData, {});
    hideStepWizard();
  };

  const autocompleteData = useMemo(() => landmarks?.map(x => ({ name: x.name, id: x.id, key: x.id })) || [], [landmarks]);

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={previousStep} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {data ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} />
      <div className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">To which landmark is this venue team assigned?</div>
          </div>
          <div>
            {data ?
              <TextField
                id="landmark-name-input"
                className="wizard-text-input"
                label="Name"
                value={landmarkName}
                onChange={(e) => handleNameChange(e.target.value)}
                name="landmark-name-input"
                placeholder="What is the name?"
                theme="outline"
              />
              :
              <AutoComplete
                id="landmark-name-input"
                className="wizard-text-input"
                label="Name"
                data={autocompleteData}
                name="landmark-name-input"
                value={landmarkName}
                placeholder="What is the name?"
                onAutoComplete={onAutoComplete}
                onChange={(e) => handleNameChange(e.target.value)}
                highlight
                labelKey="name"
                valueKey="name"
                filter="fuzzy"
              />
            }
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              disabled={!autoCompleteKey}
              onClick={handleSubmit}
              theme="primary"
              themeType="contained"
            >
              CONFIRM EDITS
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
