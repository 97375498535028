import { useState, useCallback, useMemo } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, TextField, ArrowBackSVGIcon, AutoComplete, Checkbox, useChecked } from 'react-md';
import { ProgressBar } from '../../';
import { isNotEmpty } from '../../../utils';

export default function OrgName({
  data,
  editData,
  actions,
  title,
  hideStepWizard,
  orgList,
  relationships,
  currentStep,
  nextStep
}) {
  const { update } = actions;
  const [displayName, setDisplayName] = useState(editData?.displayName || data?.childName || '');
  const [revenueShare, handleChange] = useChecked(editData?.revenueShare || data?.revenueShare || false);
  const autoCompleteData = useMemo(() => orgList.map((org) => ({ name: org.displayName, id: org.externalKey, key: org.externalKey, deactivation: org.deactivation })), [orgList]);

  const handleNameChange = (value) => {
    setDisplayName(value);
    update({
      displayName: value,
      autoCompleteKey: null,
      relationships: null
    });
  };

  const onAutoComplete = useCallback(({ value }) => {
    setDisplayName(value);
  }, []);

  const checkForDupe = () => {
    // edit flow: if name is changed make sure new name is available
    if (data?.childName !== displayName) {
      return relationships.some(rel => rel?.childName?.toLowerCase() === displayName.toLowerCase())
        || orgList.some(org => org?.displayName?.toLowerCase() === displayName.toLowerCase());
    }
  };

  const handleNext = () => {
    const selection = orgList.find((org) => org.displayName === displayName);

    update({
      displayName,
      autoCompleteKey: selection?.externalKey || null,
      relationships: selection?.relationships || null,
      deactivation: selection?.deactivation || null,
      revenueShare
    });

    nextStep();
  };

  const errorMessage = () => {
    if (!data) {
      return checkForDupe() && ` The child organization ${displayName} already exists. Continuing will allow you to add a new parent relationship for ${displayName}`;
    }

    if (data?.childName !== displayName) {
      return checkForDupe() && ` The child organization ${displayName} already exists. Please use another name`;
    }
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={hideStepWizard} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {isNotEmpty(data) ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={2} />
      <div className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">What’s the name of the child organization?</div>
          </div>
          <div>
            {isNotEmpty(data) ?
              <TextField
                id="organization-name-input"
                className="wizard-text-input"
                name="organization-name-input"
                label="Name"
                placeholder="What is the name?"
                value={displayName}
                onChange={(e) => handleNameChange(e.target.value)}
                theme="outline"
              />
              :
              <AutoComplete
                id="organization-name-input"
                className="wizard-text-input"
                name="organization-name-input"
                label="Name"
                placeholder="What is the name?"
                value={displayName}
                onChange={(e) => handleNameChange(e.target.value)}
                onAutoComplete={onAutoComplete}
                highlight
                data={autoCompleteData}
                filter="fuzzy"
                labelKey="name"
                valueKey="name"
              />
            }
            <Checkbox
              className="ph-checkbox wizard-checkbox"
              id="revenue-share"
              name="revenue-share"
              label="Revenue Share"
              onChange={handleChange}
              checked={revenueShare}
            />
            <div className="wizard-error">
              {errorMessage()}
            </div>
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              disabled={!displayName || (isNotEmpty(data) && checkForDupe())}
              onClick={handleNext}
              theme="primary"
              themeType="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
