import { createSlice } from '@reduxjs/toolkit';

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState: {
    visible: false,
    title: null,
    data: {},
    editData: {},
    allowNextStep: null
  },
  reducers: {
    stepWizardToggle: (state, action) => {
      state.data = action.payload.data;
      state.title = action.payload.title;
      state.visible = action.payload.visible;
      state.editData = action.payload.editData;
    },
    updateEditData: (state, action) => {
      state.editData = action.payload;
    },
    setWizardNextStep: (state, action) => {
      state.allowNextStep = action.payload;
    }
  }
});

export const { stepWizardToggle, updateEditData, setWizardNextStep } = wizardSlice.actions;

export default wizardSlice.reducer;
