import { Button, Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter } from 'react-md';
import { errorDialogToggle } from '../../redux/slices/errorDialog.slice';
import { useDispatch, useSelector } from 'react-redux';
import './ErrorModal.scss';

function ErrorModal() {
  const dispatch = useDispatch();
  const errorDialog = useSelector(state => state.errorDialog);

  const hide = () => {
    dispatch(errorDialogToggle({
      visible: false,
      error: null
    }));
  };

  const detailsList = (details) => {
    return details.map((detail, index) => {
      return <li key={index}>{detail.message}</li>;
    });
  };

  const error = errorDialog.error;
  const networkError = !error?.response ? error?.toString() : null;
  const errorData = error?.response?.data;
  const errorResponse = errorData ? <p className="error-message">{errorData.message}</p> : null;
  const errorId = errorData ? <p className="error-id">Paste error {errorData.id} to #ops on slack.</p> : null;
  const errorStatus = errorData ? error.response.status : null;
  const title = errorStatus ? `Error: ${errorStatus}` : 'Error:';
  const errorDetails = errorData?.details ? detailsList(errorData.details) : null;

  return (
    <div>
      <Dialog
        id="error-dialog"
        className="activation-dialog"
        aria-labelledby="error-dialog"
        visible={!!errorDialog.error || false}
        onRequestClose={hide}
        modal
      >
        <DialogHeader>
          <DialogTitle>
            {networkError || title}
          </DialogTitle>
        </DialogHeader>
        <DialogContent>
          {!networkError &&
            <div>
              {errorResponse}
              {errorDetails &&
                <ul className="details-expansion">
                  <h3>Details:</h3>
                  {errorDetails}
                </ul>
              }
              {errorId}
            </div>
          }
        </DialogContent>
        <DialogFooter className="error-dialog-button-container">
          <Button
            onClick={hide}
            className="warning-button no-elevate"
            theme="warning"
            themeType="contained"
          >
            Dismiss
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default ErrorModal;
