import { Button, Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter, useAddMessage } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import { reactivateUser } from '../../redux/slices/users.slice';
import { reactivateToggle } from '../../redux/slices/reactivateDialog.slice';

function ReactivateModal() {
  const addMessage = useAddMessage();
  const dispatch = useDispatch();
  const reactivateDialogData = useSelector(state => state.reactivateDialog.data);
  const reactivateDialogVisible = useSelector(state => state.reactivateDialog.visible);

  const hide = () => {
    dispatch(reactivateToggle({ visible: false }));
  };

  const handleConfirm = () => {
    dispatch(reactivateUser(reactivateDialogData.data, addMessage));
    dispatch(reactivateToggle({ visible: false }));
  };


  return (
    <Dialog
      id="reactivate-dialog"
      className="activation-dialog"
      aria-labelledby="reactivate-dialog"
      visible={reactivateDialogVisible}
      title="Reactivate User"

      role="alertdialog"
      modal
      onRequestClose={hide}
    >
      <DialogHeader>
        <DialogTitle>
          Reactivate User
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        User will be reactivated without any active relationships.
      </DialogContent>
      <DialogFooter
        className="activation-button-container"
      >
        <Button
          className="tertiary-button no-elevate"
          theme="primary"
          themeType="contained"
          onClick={hide}
        >
          Cancel
        </Button>
        <Button
          className="no-elevate"
          theme="primary"
          themeType="contained"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default ReactivateModal;
