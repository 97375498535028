import { Button, Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter, useAddMessage } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateToggle } from '../../redux/slices/deactivateDialog.slice';
import { clearAllFilters } from '../../redux/slices/filter.slice';

function DeactivateModal({ type }) {
  const addMessage = useAddMessage();
  const dispatch = useDispatch();
  const deactivateDialogVisible = useSelector(state => state.deactivateDialog.visible);
  const deactivateDialogData = useSelector(state => state.deactivateDialog.data);

  const hide = () => {
    dispatch(deactivateToggle({ data: null, visible: false }));
  };

  const handleConfirm = () => {
    dispatch(deactivateDialogData.action(deactivateDialogData.data, addMessage));
    dispatch(clearAllFilters());
    dispatch(deactivateToggle({ data: null, visible: false }));
  };

  const deactivateTitle = {
    Organizations: 'Deactivate organization?',
    People: 'Deactivate User?'
  };

  let subTitle;

  if (type === 'People' && deactivateDialogVisible) {
    const { displayName, relationships } = deactivateDialogData.data;
    // if the relationship object exists, create a rel string from it to
    // display in the subtitle. Relationship array can NOT exist if the person
    // is orphaned after a reactivation or otherwise
    const relationshipArray = relationships === null ? [] : relationships.map(r => r.displayName);
    const relString =
      relationshipArray.length === 0
        ? 'no organizations'
        : relationshipArray.join(', ');
    subTitle = `${displayName} is associated to ${relString} and will lose permission to access their data.`;
  } else {
    subTitle = 'This relationship will be deactivated in the database.';
  }

  return (
    <Dialog
      id="deactivate-dialog"
      className="activation-dialog"
      role="alertdialog"
      modal
      visible={deactivateDialogVisible}
      onRequestClose={hide}
      aria-labelledby="deactivate-dialog"
    >
      <DialogHeader>
        <DialogTitle>
          {deactivateDialogData && deactivateTitle[deactivateDialogData.title]}
        </DialogTitle>
      </DialogHeader>
      <DialogContent>
        {subTitle}
      </DialogContent>
      <DialogFooter
        className="activation-button-container"
      >
        <Button
          className="tertiary-button no-elevate"
          theme="primary"
          themeType="contained"
          onClick={hide}
        >
          Cancel
        </Button>
        <Button
          className="warning-button no-elevate"
          theme="warning"
          themeType="contained"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default DeactivateModal;
