import { useState, useEffect, useCallback } from 'react';
import { Button, AutoComplete } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../../redux/slices/organizations.slice';
import { buildTree } from '../../redux/slices/tree.slice';

export default function TreePicker({ nextStep }) {
  const dispatch = useDispatch();
  const orgList = useSelector(state => state.organizations.data);
  const [buttonText, setButtonText] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [externalKey, setExternalKey] = useState('');
  const [lastKey, setLastKey] = useState('');

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const clearDisplayName = () => {
    setDisplayName('');
    setExternalKey('');
    setButtonText('');
  };

  const build = () => {
    if (externalKey !== lastKey) {
      dispatch(buildTree(externalKey));
      setLastKey(externalKey);
    }

    nextStep();
  };

  function handleNameChange(value) {
    setDisplayName(value);
    setExternalKey('');
    setButtonText('');
  }

  const onAutoComplete = useCallback((data) => {
    const oldKey = externalKey;
    const org = orgList.filter(x => x.displayName === data.result.name)[0];

    setDisplayName(org.displayName);
    setButtonText(org.displayName);
    setLastKey(oldKey);
    setExternalKey(org.externalKey);
  }, [externalKey, orgList]);

  const autocompleteData = orgList && orgList.map(x => {
    return { name: x.displayName, id: x.externalKey, key: x.externalKey };
  });

  return (
    <div className="wrapper">
      <div className="wizard-step">
        <div className="wizard-step-header-container">
          <div className="wizard-step-header">Which organization would you like to view?</div>
        </div>
        <div>
          <AutoComplete
            id="tree-name-input"
            name="tree-name-input"
            className="wizard-text-input"
            label="Name"
            value={displayName}
            onChange={(e) => handleNameChange(e.target.value)}
            onAutoComplete={onAutoComplete}
            highlight
            data={autocompleteData}
            filter="fuzzy"
            labelKey="name"
            valueKey="name"
          />
          <Button
            className="clearButton"
            disabled={displayName.length < 1}
            onClick={clearDisplayName}
          >
            Clear
          </Button>
        </div>
        <div>
          <Button
            className="wizard-button no-elevate"
            disabled={externalKey !== '' ? false : true}
            onClick={build}
            theme="primary"
            themeType="contained"
          >
            {buttonText.length > 1 ? `View ${buttonText}` : 'Next Page'}
          </Button>
        </div>
      </div>
    </div>
  );
}
