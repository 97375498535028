import { useEffect } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, ArrowBackSVGIcon, useAddMessage } from 'react-md';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { ProgressBar } from '../../';
import TreeContainer from '../../TreeContainer/TreeContainer';
import { isNotEmpty, checkForAccess } from '../../../utils';
import { postOperators, deleteOperators } from '../../../redux/slices/operators.slice';
import { clearSearchTerm } from '../../../redux/slices/filter.slice';
import { buildTree } from '../../../redux/slices/tree.slice';
import { refreshUsers, sendRegistrationEmail } from '../../../redux/slices/users.slice';

export default function UserReviewAndConfirm({
  data,
  editData,
  previousStep,
  title,
  hideStepWizard,
  currentStep,
  totalSteps
}) {
  const addMessage = useAddMessage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.relationships) {
      dispatch(buildTree(data.relationships[0].fromPartyKey));
    }
  }, [data, dispatch]);

  function handleEmail(sendEmail) {
    if (data && sendEmail) {
      dispatch(sendRegistrationEmail({ externalKey: data.externalKey, displayName: data.displayName }, addMessage));
    }

    if (editData && sendEmail) {
      dispatch(sendRegistrationEmail({ externalKey: editData.externalKey, displayName: editData.displayName }, addMessage));
    }

    if (editData && isNotEmpty(editData.operators)) {
      dispatch(postOperators(editData.externalKey, editData.operators, addMessage));
    }

    if (editData && isNotEmpty(editData.removeOperators)) {
      dispatch(deleteOperators(editData.externalKey, editData.removeOperators, addMessage));
    }

    dispatch(refreshUsers());
    dispatch(clearSearchTerm());

    hideStepWizard();
  }

  const canInvite = checkForAccess('suite.users.persons.invite', 'WRITE');

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={totalSteps > 1 ? previousStep : hideStepWizard} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {data?.email ? `Creating a new ${title.toLowerCase()}` : data ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={totalSteps} />
      <div className="wrapper">
        <div className="wizard-step confirm-wizard">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">{canInvite ? 'Send registration email?' : 'Review and Confirm'}</div>
            <div className="wizard-step-subheader">{`${editData ?
              `${editData.firstName} ${editData.lastName}` : data?.email ? data?.displayName : 'This user'} will be able to see the following lots in Analytics:`}</div>
          </div>
          <div id="relation-tree-container">
            <TreeContainer />
          </div>
          <div className={cx('wizard-email-container', { center: !canInvite })}>
            {canInvite ?
              <>
                <Button
                  id="person-wizard-registration-later"
                  className="wizard-button no-elevate"
                  onClick={() => data?.email ? hideStepWizard() : handleEmail(false)}
                  theme="secondary"
                  themeType="contained"
                >
                  NOT NOW
                </Button>
                <Button
                  id="person-wizard-registration-now"
                  className="wizard-button no-elevate"
                  onClick={() => handleEmail(true)}
                  theme="primary"
                  themeType="contained"
                >
                  SEND EMAIL
                </Button>
              </>
              :
              <Button
                id="person-wizard-registration-confirm"
                className="wizard-button no-elevate"
                onClick={() => data?.email ? hideStepWizard() : handleEmail(false)}
                theme="secondary"
                themeType="contained"
              >
                Confirm
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  );
};
