import { forwardRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, AppBarNav, AppBarTitle, Button, ArrowBackSVGIcon, useAddMessage } from 'react-md';
import Select from 'react-select';
import { upperCase, isEmpty } from 'lodash';
import { ProgressBar } from '../../';
import { clearSearchTerm } from '../../../redux/slices/filter.slice';
import { createNewUser, updateUser } from '../../../redux/slices/users.slice';

function UserRoleSelect({
  data,
  actions,
  nextStep,
  editData,
  title,
  options,
  innerRef,
  previousStep,
  currentStep,
  totalSteps
}) {
  const dispatch = useDispatch();
  const addMessage = useAddMessage();
  const [role, setRole] = useState(editData?.role || null);
  const [organizations, setOrganizations] = useState(editData?.orgs || null);
  const [submitted, setSubmitted] = useState(false);
  const allowNextStep = useSelector(state => state.wizard.allowNextStep);
  const { update } = actions;

  useEffect(() => {
    if (data) {
      const currentRole = data?.relationships?.[0]?.relationshipType || null;

      const orgs = data?.relationships?.map(rel => {
        return {
          value: rel.fromPartyKey,
          label: rel.displayName,
          relationshipKey: rel.relationshipKey
        };
      }) || null;

      if (currentRole) {
        setRole({ value: currentRole, label: upperCase(currentRole) });
      }
      if (orgs) {
        setOrganizations(orgs);
      }
    }
  }, [data]);

  useEffect(() => {
    if (allowNextStep && submitted) {
      nextStep();
    }
  }, [nextStep, allowNextStep, submitted]);

  const confirmPerson = (edited, person) => {
    dispatch(clearSearchTerm());
    return edited ? dispatch(updateUser(person, addMessage)) : dispatch(createNewUser(person.edits, addMessage));
  };

  const handleRoleChange = (obj) => {
    setRole(obj);
    setOrganizations(null);
    update({
      role: obj,
      newRelationshipType: obj.value,
      currentRelationshipType: data?.relationships ? data.relationships.relationshipType : null
    });
  };

  const handleOrgChange = (value) => {
    setOrganizations(value);

    const formattedData = value?.map(x => {
      return {
        displayName: x.label,
        fromPartyKey: x.value,
        relationshipType: role.value,
        relationshipKey: x.relationshipKey && x.relationshipKey
      };
    });
    if (formattedData) {
      update({ relationships: formattedData, orgs: value });
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);

    if (!data && editData.created) {
      confirmPerson(!data, {
        edits: editData,
        original: editData.created
      });
    }

    if (!data && !editData.created) {
      confirmPerson(!!data, { edits: editData });
    }

    if (data && editData) {
      confirmPerson(!!data, {
        edits: editData,
        original: data
      });
    }

    if (data && !editData) {
      nextStep();
    }
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={previousStep} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {data ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={data ? 4 : totalSteps} />
      <div ref={innerRef} className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">What role should they have?</div>
            <div className="wizard-step-subheader">This is the role they have when accessing ParkHub products, not necessarily their role and title within the company.</div>
          </div>
          <div className="wizard-select-role-container">
            <Select
              classNamePrefix="wizard-select"
              id="person-role-select"
              className="wizard-select"
              placeholder="Role"
              defaultValue={role}
              value={role}
              onChange={handleRoleChange}
              options={options.roleOptions}
            />
            <Select
              isMulti
              backspaceRemovesValue={false}
              isClearable
              isDisabled={!role}
              classNamePrefix="wizard-select"
              id="person-organization-select"
              className="wizard-select"
              placeholder="Organization"
              defaultValue={organizations}
              value={organizations}
              options={role ? options.userOrgOptions[role.value] : options.orgOptions}
              onChange={handleOrgChange}
            />
          </div>
          <div className="wizard-error">
            {isEmpty(organizations) && 'Organization Required'}
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              onClick={handleSubmit}
              disabled={isEmpty(role) || isEmpty(organizations)}
              theme="primary"
              themeType="contained"
            >
              continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef((props, ref) => <UserRoleSelect innerRef={ref} {...props} />);
