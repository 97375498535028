import { createSlice } from '@reduxjs/toolkit';
import { create } from 'axios';
import _ from 'lodash';
import { applyLotFilter } from './filter.slice';
import { errorDialogToggle } from './errorDialog.slice';

const ajax = create({
  contentType: 'application/json',
  baseURL: process.env.REACT_APP_CORE_USER_API_URL
});

ajax.defaults.withCredentials = true;

export const lotsSlice = createSlice({
  name: 'lots',
  initialState: {
    data: [],
    isLoading: false,
    isError: false
  },
  reducers: {
    getLotsPending: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    getLotsRejected: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    getLotsSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    updateLotPending: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    updateLotRejected: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    updateLotSuccess: (state) => {
      state.isLoading = false;
      state.isError = false;
    }
  }
});

export default lotsSlice.reducer;

export const {
  getLotsPending,
  getLotsRejected,
  getLotsSuccess,
  updateLotPending,
  updateLotRejected,
  updateLotSuccess
} = lotsSlice.actions;

export const getLots = () => dispatch => {
  dispatch(getLotsPending());

  ajax.get('/organizations/lots')
    .then(resp => {
      dispatch(getLotsSuccess(resp.data.associations));
    })
    .catch(error => {
      dispatch(getLotsRejected());
      dispatch(errorDialogToggle({ error, visible: true }));
    });
};

export const updateLot = (data, addMessage) => dispatch => {
  dispatch(updateLotPending());

  const editLot = data.edits;
  const originalLot = data.original;
  const originalPartyKeys = originalLot.organizations ? originalLot.organizations.map(j => j.partyKey) : [];
  const editPartyKeys = editLot.organizations.map(j => j.externalKey);
  const postKeys = editPartyKeys.filter(x => !originalPartyKeys.includes(x));
  const delKeys = originalPartyKeys.filter(x => !editPartyKeys.includes(x));

  const postBody = {
    'associations': [
      {
        lotId: editLot.externalKey,
        organizationKeys: postKeys
      }
    ]
  };

  const delBody = {
    data: {
      'associations': [
        {
          lotId: editLot.externalKey,
          organizationKeys: delKeys
        }
      ]
    }
  };

  const successToast = `Lot/Garage: ${originalLot && originalLot.displayName} has been edited`;

  if (!_.isEmpty(postKeys) && !_.isEmpty(delKeys)) {
    ajax.post('/organizations/lots', postBody)
      .then(() => dispatch(updateLotSuccess()))
      .then(() => ajax.delete('/organizations/lots', delBody))
      .then(() => {
        dispatch(applyLotFilter(null));
        dispatch(getLots());
        addMessage({ children: successToast, action: 'dismiss' });
      })
      .catch(error => {
        console.error(error);

        dispatch(updateLotRejected(error));
        dispatch(errorDialogToggle({ error, visible: true }));
      });
  } else if (!_.isEmpty(postKeys) && _.isEmpty(delKeys)) {
    ajax.post('/organizations/lots', postBody)
      .then(() => dispatch(updateLotSuccess()))
      .then(() => {
        dispatch(applyLotFilter(null));
        dispatch(getLots());
        addMessage({ children: successToast, action: 'dismiss' });
      })
      .catch(error => {
        console.error(error);

        dispatch(updateLotRejected(error));
        dispatch(errorDialogToggle({ error, visible: true }));
      });

  } else if (_.isEmpty(postKeys) && !_.isEmpty(delKeys)) {
    ajax.delete('/organizations/lots', delBody)
      .then(() => dispatch(updateLotSuccess()))
      .then(() => {
        dispatch(applyLotFilter(null));
        dispatch(getLots());
        addMessage({ children: successToast, action: 'dismiss' });
      })
      .catch(error => {
        console.error(error);

        dispatch(updateLotRejected(error));
        dispatch(errorDialogToggle({ error, visible: true }));
      });
  }
};
