import ParkhubLogo from '../../assets/parkhub-organization-logo.svg';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '@parkhub/parkhub-ui';
import { LotsGarages, People, Organizations, OrganizationsTree, TabNavigation } from '../';
import * as routes from '../../constants/routes';
import { signOut, getAuthUser, checkForAccess } from '../../utils';
import { reactivateToggle } from '../../redux/slices/reactivateDialog.slice';
import { deactivateToggle } from '../../redux/slices/deactivateDialog.slice';
import { stepWizardToggle } from '../../redux/slices/wizard.slice';
import { clearAllFilters } from '../../redux/slices/filter.slice';
import './Layout.scss';

function Layout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = getAuthUser();
  const access = useSelector((state) => state.session.featureAccess);

  function showStepWizard(params) {
    dispatch(stepWizardToggle({ ...params, visible: true }));
  };

  function hideStepWizard() {
    dispatch(stepWizardToggle({ data: null, title: null, editData: null, visible: false }));
    dispatch(clearAllFilters());
  };

  function showDeactivateDialog(data) {
    dispatch(deactivateToggle({ data, visible: true }));
  };

  function hideDeactivateDialog() {
    dispatch(deactivateToggle({ data: null, visible: false }));
  };

  function showReactivateDialog(data) {
    dispatch(reactivateToggle({ data, visible: true }));
  };

  function hideReactivateDialog() {
    dispatch(reactivateToggle({ data: null, visible: false }));
  };

  const actions = {
    hideStepWizard,
    showStepWizard,
    hideDeactivateDialog,
    showDeactivateDialog,
    showReactivateDialog,
    hideReactivateDialog
  };

  const tabNavConfig = [
    {
      name: 'Organizations',
      path: routes.ORGANIZATIONS,
      allowed: checkForAccess('suite.users.organizations')
    },
    {
      name: 'People',
      path: routes.PEOPLE,
      allowed: checkForAccess('suite.users.persons')
    },
    {
      name: 'Lots/Garages',
      path: routes.LOTS_GARAGES,
      allowed: checkForAccess('suite.users.lot_associations')
    },
    {
      name: 'Organizations Tree',
      path: routes.ORGANIZATIONS_TREE,
      allowed: checkForAccess('suite.users.organizations_tree'),
      target: '_blank'
    }
  ];

  const allowedRoutes = tabNavConfig.filter((item) => !!item.allowed);

  return (
    <>
      {authUser &&
        <>
          <Header
            authUser={authUser}
            access={access}
            signOut={() => signOut()}
            logoSrc={ParkhubLogo}
            logoWidth={260}
            appMenuActive
            logoClick={() => navigate(routes.ROOT)}
          >
            <TabNavigation
              navConfig={allowedRoutes}
              defaultTab={location.pathname.split('/')[1]}
            />
          </Header>
          <main id="layout">
            <div className="wrapper">
              <div className="row center-xs">
                <div className="col-xs-9">
                  <Routes location={location}>
                    {checkForAccess('suite.users.organizations') &&
                      <Route path={routes.ORGANIZATIONS} element={<Organizations {...props} actions={actions} />} />
                    }
                    {checkForAccess('suite.users.persons') &&
                      <Route path={routes.PEOPLE} element={<People {...props} actions={actions} />} />
                    }
                    {checkForAccess('suite.users.lot_associations') &&
                      <Route path={routes.LOTS_GARAGES} element={<LotsGarages {...props} actions={actions} />} />
                    }
                    {checkForAccess('suite.users.organizations_tree') &&
                      <Route path={routes.ORGANIZATIONS_TREE} element={<OrganizationsTree {...props} actions={actions} />} />
                    }
                    <Route path="/" element={<Navigate replace to={routes.ORGANIZATIONS} />} />
                  </Routes>
                </div>
              </div>
            </div>
          </main>
        </>
      }
    </>
  );
}

export default Layout;
