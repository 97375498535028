import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import './TabNavigation.scss';

export default function TabNavigation({
  navConfig,
  defaultTab
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    setActiveTabIndex(_.findIndex(navConfig, o => o.path.includes(defaultTab)));
  }, [defaultTab, navConfig]);

  return (
    <div className="tabs-wrapper">
      <nav className="tab-navigation">
        <div className="tab-navigation-menu">
          <div className={`link-container active-${activeTabIndex}`}>
            {navConfig.map((item, index) => (
              item.allowed && (
                <NavLink
                  key={item.name}
                  to={item.path}
                  target={item.target}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {item.name}
                  <span className="bottom-border" />
                </NavLink>
              )
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
}
