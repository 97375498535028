const BuildingIcon = props => (
  <svg className="building-icon" viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7V3H2v18h20V7H12zM5.99994 19h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4.00001h-2v-2h2v2zM9.99997 19h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4.00001h-2v-2h2v2zM20 19h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2.0001-8h-2v2h2v-2zm0 4h-2v2h2v-2z"
      fill="currentColor"
    />
  </svg>
)
export default BuildingIcon;
