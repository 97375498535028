import { useState, useEffect } from 'react';
import { Button } from 'react-md';
import _ from 'lodash';
import cx from 'classnames';
import { ThItem, NoTableData } from '../';
import LotsGaragesTableActionBar from './LotsGaragesTableActionBar';
import LotsGaragesTableMenu from './LotsGaragesTableMenu';
import { idFormatter, formatArrayCells } from '../../utils';

export default function LotsGaragesTable({
  data,
  title,
  actions,
  labelKeys,
  isLoading
}) {
  const [initialData, setInitialData] = useState([]);
  const [activeSortKey, setActiveSortKey] = useState('');
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (data) {
      setInitialData(data);
    }
  }, [data]);

  const formatData = (key, value) => {
    if (key.includes('organization')) {
      return value?.organizations?.map(x => x.displayName);
    } else if (key.includes('displayName')) {
      return value[key];
    } else {
      return value[key] ? value[key] : null;
    }
  };

  const resetSort = () => setActiveSortKey('');

  const sortData = (key, direction = 'asc', sortable) => {
    if (sortable) {
      setActiveSortKey(key);
      setInitialData(_.orderBy(data, [x => x[key] && x[key].toString().toLowerCase()], direction));
    }
  };

  const dataStyle = (item) => item.status === 'failed' ? 'rgba(239, 83, 80, 0.2)' : item.status === 'pending' && 'rgba(253, 216, 53, 0.2)';
  const results = initialData?.length > 0 ? initialData : data;
  const tableData = loadMore ? results : results.slice(0, 50);

  return (
    <div className="ph-table">
      <LotsGaragesTableActionBar title={title} />
      <table>
        <thead >
          <tr>
            {labelKeys?.map((lk, index) => (
              <th
                key={index}
                className={
                  cx([{ 'disabled-sort': lk.sortable === false }])
                }
              >
                <ThItem
                  id={`table-head-${idFormatter(lk.key)}-sort`}
                  activeSortKey={activeSortKey}
                  sortKey={lk.sortable && lk.key}
                  sortable={lk.sortable}
                  onClick={sortData}
                >
                  {lk.label}
                </ThItem>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, index) => {
            const inactive = new Date(item.deactivation) < new Date();
            return (
              <tr
                id={`table-row-${index}`}
                style={{ backgroundColor: dataStyle(item) }}
                key={index}
              >
                {labelKeys.map((lk, keyIndex) => {
                  const formattedItem = formatData(lk.key, item);

                  return (
                    <td
                      key={keyIndex}
                      title={_.isArray(formattedItem) ? formattedItem : ''}
                      className={
                        cx([`lot-${lk.key}`])
                      }
                    >
                      {inactive ? '' :
                        _.isArray(formattedItem) ?
                          formatArrayCells(formattedItem) :
                          item[lk.key]
                      }
                    </td>
                  );
                })}
                <td className="table-edit-button">
                  <LotsGaragesTableMenu
                    resetSort={resetSort}
                    className="tb-table-menu"
                    actions={actions}
                    item={[item]}
                    title={title}
                    index={index}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length <= 0 && !isLoading && <NoTableData />}
      {data.length > 0 && tableData.length < data.length &&
        <Button
          className="load-more-button"
          theme="secondary"
          themeType="outline"
          onClick={() => setLoadMore(true)}
        >
          load more
        </Button>
      }
    </div>
  );
}
