import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { FilterChip, FilterBar, SearchInput } from '../';
import { FUSE_OPTIONS, isNotEmpty } from '../../utils';
import { applyLotFilter, applyLotSearch, clearSearchTerm, setTeamFilter } from '../../redux/slices/filter.slice';
import { getOrganizations } from '../../redux/slices/organizations.slice';

export default function LotsGaragesTableActionBar({ title }) {
  const dispatch = useDispatch();
  const organizations = useSelector(state => state.organizations.data);
  const lots = useSelector(state => state.lots.data);
  const teamFilter = useSelector(state => state.filter.teamFilter);

  useEffect(() => {
    if (!isNotEmpty(organizations)) {
      dispatch(getOrganizations());
    }
  }, [organizations, dispatch]);

  const applyFilter = (filterArr) => {
    const filteredLotData = [];

    lots.forEach(x => {
      if (x?.organizations?.some(r => filterArr.includes(r.partyKey))) {
        filteredLotData.push(x);
      }
    });

    dispatch(clearSearchTerm());
    return dispatch(applyLotFilter(filteredLotData));
  };

  const removeFilter = () => {
    dispatch(applyLotFilter(null));
    dispatch(clearSearchTerm());
  };

  const handleSearch = (term) => {
    const searchList = lots;
    const fuse = new Fuse(searchList, FUSE_OPTIONS);
    const results = fuse.search(term).map(result => result.item);

    dispatch(applyLotSearch(results, term));
  };

  return (
    <div className="table-action-bar">
      <FilterBar>
        {isNotEmpty(teamFilter) &&
          <FilterChip
            label="Teams"
            onApply={applyFilter}
            onRemove={removeFilter}
            initialData={teamFilter}
            setInitialData={options => dispatch(setTeamFilter(options))}
          />
        }
        <SearchInput
          id={`${title.toLowerCase()}-search-input`}
          handleSearch={handleSearch}
        />
      </FilterBar>
    </div>
  );
}
