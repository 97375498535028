import { createSlice } from '@reduxjs/toolkit';
import { create } from 'axios';

const ajax = create({
  contentType: 'application/json',
  baseURL: process.env.REACT_APP_CORE_USER_API_URL
});

ajax.defaults.withCredentials = true;

export const treeSlice = createSlice({
  name: 'tree',
  initialState: {
    organizationTree: { name: 'Loading...', children: [] },
    isLoading: false,
    isError: false
  },
  reducers: {
    setOrgTreePending: (state) => {
      state.organizationTree = { name: 'Loading...', children: [] };
      state.isLoading = true;
      state.isError = false;
    },
    setOrgTreeRejected: (state) => {
      state.organizationTree = { name: 'Error loading tree.', children: [] };
      state.isLoading = false;
      state.isError = true;
    },
    setOrgTreeSuccess: (state, action) => {
      state.organizationTree = action.payload;
      state.isLoading = false;
    }
  }
});

export default treeSlice.reducer;

export const {
  setOrgTreePending,
  setOrgTreeRejected,
  setOrgTreeSuccess
} = treeSlice.actions;

export const buildTree = externalKey => dispatch => {
  dispatch(setOrgTreePending());

  ajax.get(`/organizations/${externalKey}/tree`)
    .then(resp => {
      dispatch(setOrgTreeSuccess(resp.data.registrationTree));
    })
    .catch(() => dispatch(setOrgTreeRejected()));
};
