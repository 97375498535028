import { createSlice } from '@reduxjs/toolkit';

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    orgFilteredData: false,
    clientFilter: [],
    teamFilter: [],
    peopleFilteredData: null,
    lotsFilteredData: null,
    searchTerm: ''
  },
  reducers: {
    setClientFilter: (state, action) => {
      if (action.payload === null) {
        state.clientFilter = state.clientFilter.map(x => ({ ...x, isChecked: true }));
      } else {
        state.clientFilter = action.payload;
      }
    },
    setTeamFilter: (state, action) => {
      if (action.payload === null) {
        state.teamFilter = state.teamFilter.map(x => ({ ...x, isChecked: true }));
      } else {
        state.teamFilter = action.payload;
      }
    },
    applyOrgFilter: (state, action) => {
      state.orgFilteredData = action.payload;
    },
    applyPeopleFilter: (state, action) => {
      state.peopleFilteredData = action.payload;
    },
    applyLotFilter: (state, action) => {
      state.lotsFilteredData = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    clearSearchTerm: (state) => {
      state.searchTerm = '';
    }
  }
});

export default filterSlice.reducer;

export const {
  applyOrgFilter,
  applyPeopleFilter,
  applyLotFilter,
  setSearchTerm,
  clearSearchTerm,
  clientFilter,
  teamFilter,
  setClientFilter,
  setTeamFilter
} = filterSlice.actions;

export const applyOrgSearch = (data, term) => dispatch => {
  dispatch(setClientFilter(null));
  dispatch(setTeamFilter(null));

  if (data.length === 0) {
    if (term === '') {
      dispatch(applyOrgFilter(null));
    } else {
      dispatch(applyOrgFilter([]));
    }
  } else {
    dispatch(applyOrgFilter(data));
  }
};

export const applyPeopleSearch = (data, term) => dispatch => {
  dispatch(setClientFilter(null));
  dispatch(setTeamFilter(null));

  if (data.length === 0) {
    if (term === '') {
      dispatch(applyPeopleFilter(null));
    } else {
      dispatch(applyPeopleFilter([]));
    }
  } else {
    dispatch(applyPeopleFilter(data));
  }
};

export const applyLotSearch = (data, term) => dispatch => {
  dispatch(setTeamFilter(null));

  if (data.length === 0) {
    if (term === '') {
      dispatch(applyLotFilter(null));
    } else {
      dispatch(applyLotFilter([]));
    }
  } else {
    dispatch(applyLotFilter(data));
  }
};

export const clearAllFilters = () => dispatch => {
  dispatch(applyLotFilter(null));
  dispatch(applyPeopleFilter(null));
  dispatch(applyOrgFilter(null));
  dispatch(setClientFilter(null));
  dispatch(setTeamFilter(null));
  dispatch(clearSearchTerm());
};
