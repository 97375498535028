import { createSlice } from '@reduxjs/toolkit';

export const errorDialogSlice = createSlice({
  name: 'errorDialog',
  initialState: {
    error: null,
    visible: false
  },
  reducers: {
    errorDialogToggle: (state, action) => {
      state.error = action.payload.error;
      state.visible = action.payload.visible;
    }
  }
});

export const { errorDialogToggle } = errorDialogSlice.actions;

export default errorDialogSlice.reducer;
