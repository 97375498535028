import { createSlice } from '@reduxjs/toolkit';
import { create } from 'axios';
import _ from 'lodash';
import { getSuggestedOperators } from './operators.slice';
import { buildTree } from './tree.slice';
import { applyPeopleFilter } from './filter.slice';
import { updateEditData, setWizardNextStep } from './wizard.slice';
import { errorDialogToggle } from './errorDialog.slice';

const ajax = create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  contentType: 'application/json',
  withCredentials: true
});

ajax.defaults.withCredentials = true;

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    user: [],
    tempData: [],
    selectedData: [],
    editEnabled: false,
    isLoading: false,
    isError: false
  },
  reducers: {
    getTeamPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    getTeamRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    getTeamsSuccess: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
      state.isError = null;
    },
    createUserPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    createUserRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    createUserSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    updateUserPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    updateUserRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    updateUserSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    reactivateUserPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    reactivateUserRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    reactivateUserSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },

    deleteUserPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    deleteUserRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    deleteUserSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    sendRegistrationEmailPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    sendRegistrationEmailRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    sendRegistrationEmailSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    }
  }
});

export default usersSlice.reducer;

export const {
  getTeamPending,
  getTeamRejected,
  getTeamsSuccess,
  createUserPending,
  createUserRejected,
  createUserSuccess,
  updateUserPending,
  updateUserRejected,
  updateUserSuccess,
  reactivateUserPending,
  reactivateUserRejected,
  reactivateUserSuccess,
  deleteUserPending,
  deleteUserRejected,
  deleteUserSuccess,
  sendRegistrationEmailPending,
  sendRegistrationEmailRejected,
  sendRegistrationEmailSuccess
} = usersSlice.actions;

export const getUsers = () => dispatch => {
  dispatch(getTeamPending());

  ajax.get('/persons?attachRelationships=true&activeOnly=false')
    .then(resp => {
      const formattedUsers = resp.data.persons.map(x => {
        if (x.relationships) {
          x.role = x.relationships[0].relationshipType;
        }

        return x;

      }).filter(x => x.role !== 'GLOBAL_ADMIN');

      dispatch(getTeamsSuccess({ data: formattedUsers }));
      dispatch(applyPeopleFilter(null));
    })
    .catch(error => {
      console.error(error);
      dispatch(getTeamRejected(error));
    });
};

export const createNewUser = (user, addMessage) => dispatch => {
  dispatch(createUserPending());

  const newUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    displayName: `${user.firstName} ${user.lastName}`,
    email: user.email,
    relationships: user.relationships.map(x => {
      return {
        fromPartyKey: x.fromPartyKey,
        relationshipType: x.relationshipType
      };
    })
  };

  dispatch(buildTree(newUser.relationships[0].fromPartyKey));

  ajax.post('/persons', newUser)
    .then(resp => {
      // set fields for "editData" if user wishes to go to previous steps in wizard to edit
      newUser.externalKey = resp.data.externalKey;
      // after assigning a person role(s) the person is created
      // users can then backtrack optionaly editing fields which will need update after role selection
      // the created property is assigned to editUser to track person details to compare possibly updated values
      newUser.created = {
        displayName: newUser.displayName,
        email: newUser.email,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        relationships: newUser.relationships
      };

      newUser.role = user.role;
      newUser.orgs = user.orgs;

      dispatch(updateEditData(newUser));
      dispatch(getSuggestedOperators(newUser.externalKey));
      dispatch(createUserSuccess());
      dispatch(setWizardNextStep(true));

    })
    .then(() =>
      addMessage({ children: `User: ${newUser.displayName} has been created`, action: 'dismiss' })
    )
    .catch(error => {
      console.error(error);
      newUser.created = false;
      dispatch(updateEditData(newUser));
      dispatch(createUserRejected(error));
      dispatch(setWizardNextStep(false));
      dispatch(errorDialogToggle({ error, visible: true }));
    });
};

export const updateUser = data => dispatch => {
  const editUser = { ...data.edits };
  const originalUser = { ...data.original };

  const userEdits = {
    firstName: editUser.firstName,
    lastName: editUser.lastName,
    displayName: `${editUser.firstName} ${editUser.lastName}`,
    email: editUser.email
  };

  const originalUserBody = {
    firstName: originalUser.firstName,
    lastName: originalUser.lastName,
    displayName: `${originalUser.firstName} ${originalUser.lastName}`,
    email: originalUser.email
  };

  const originalRelationshipState = data.edits.relationships.map(r => {
    return {
      fromPartyKey: r.fromPartyKey,
      relationshipType: r.relationshipType,
      toPartyKey: r.toPartyKey
    };
  });

  /* Build the body for the PUT /api/persons/:externalKey/relationships endpoint that checks that
   * new state of a party's relationships is valid with respect to the rules.

   * if it is the same as originalRelationshipState, we do not need to make the
   * AJAX call to PUT /api/organizations/:externalKey/relationships */
  const newRelationshipState = data.edits.relationships.map(r => {
    return {
      toPartyKey: editUser.externalKey, // same for all since editing single person.
      fromPartyKey: r.fromPartyKey,
      relationshipType: r.relationshipType
    };
  });

  /* If the person attributes controlled by PUT person endpoint were changed,
  * make the AJAX call to change them.*/
  const isPersonAttributesEdited = userEdits.displayName !== originalUserBody.displayName || userEdits.email !== originalUserBody.email;

  let updateError = null;

  if (isPersonAttributesEdited) {
    dispatch(updateUserPending());

    ajax.put(`/persons/${editUser.externalKey}`, userEdits)
      .then(() => {
        dispatch(updateUserSuccess());
        editUser.created = {
          displayName: editUser.displayName,
          email: editUser.email,
          firstName: editUser.firstName,
          lastName: editUser.lastName,
          orgs: editUser.orgs,
          role: editUser.role
        };

        dispatch(updateEditData(editUser));
      })
      .catch(error => {
        console.log('error: ', error);
        dispatch(updateUserRejected(error));
        dispatch(setWizardNextStep(false));
        updateError = true;
        editUser.created = false;
        dispatch(updateEditData(editUser));
        dispatch(errorDialogToggle({ error, visible: true }));
      });
  }

  /* If the new relationship state is different than the old relationship state
     * (e.g. the relationships were edited in the UI), make an AJAX call to
     * attempt to change them with the state change endpoint. */
  const isRelationshipStateEdited = !_.isEqual(
    newRelationshipState,
    originalRelationshipState
  );

  if (isRelationshipStateEdited) {
    ajax.put(`/organizations/${editUser.externalKey}/relationships`, { relationships: newRelationshipState })
      .then(() => {
        editUser.created = {
          displayName: editUser.displayName,
          email: editUser.email,
          firstName: editUser.firstName,
          lastName: editUser.lastName,
          orgs: editUser.orgs,
          role: editUser.role
        };

        dispatch(updateEditData(editUser));
      })
      .catch(error => {
        console.log('error: ', error);
        updateError = true;
        editUser.created = false;
        dispatch(updateEditData(editUser));
        dispatch(errorDialogToggle({ error, visible: true }));
      });
  }

  if (!updateError || (!isPersonAttributesEdited && !isRelationshipStateEdited)) {
    dispatch(setWizardNextStep(true));
  }
};

export const deactivateUser = (user, addMessage) => dispatch => {
  dispatch(deleteUserPending());

  ajax.delete(`/persons/${user.externalKey}`)
    .then(resp => {
      dispatch(deleteUserSuccess());

      return resp.data;
    })
    .then(() => {
      dispatch(getUsers());

      return true;
    })
    .then(() => {
      addMessage({ children: `${user.displayName} and their relationships have been deactivated.`, action: 'dismiss' });
    })
    .catch(error => {
      console.error(error);
      dispatch(deleteUserRejected(error));
      dispatch(errorDialogToggle({ error, visible: true }));
    });
};

export const reactivateUser = (user, addMessage) => dispatch => {
  dispatch(reactivateUserPending());

  ajax.put(`/persons/${user.externalKey}?action=REACTIVATE`)
    .then(resp => {
      dispatch(reactivateUserSuccess());

      return resp.data;
    })
    .then(() => {
      dispatch(getUsers());

      return true;
    })
    .then(() => {
      addMessage({ children: `${user.displayName} has been reactivated without relationships.`, action: 'dismiss' });
    })
    .catch(error => {
      console.error(error);
      dispatch(reactivateUserRejected(error));
      dispatch(errorDialogToggle({ error, visible: true }));
    });
};

export const getEmailAvailability = (email) => {
  return ajax.post(`/persons/emails`, { email })
    .then(resp => {
      if (resp.data.hasOwnProperty('status')) {
        return resp.data.status === 'AVAILABLE';
      }
    });
};

export const refreshUsers = () => dispatch => {
  dispatch(getUsers());
  dispatch(applyPeopleFilter(null));
};

export const sendRegistrationEmail = (editUser, addMessage) => dispatch => {
  dispatch(sendRegistrationEmailPending());

  ajax.post(`/persons/${editUser.externalKey}/invite`)
    .then(() => {
      dispatch(sendRegistrationEmailSuccess());
      addMessage({ children: `User ${editUser.displayName} sent registration email`, action: 'dismiss' });
    })
    .then(() => {
      dispatch(getUsers());
      dispatch(applyPeopleFilter(null));
    })
    .catch(error => {
      console.log('error: ', error);
      dispatch(sendRegistrationEmailRejected(error));
      dispatch(errorDialogToggle({ error, visible: true }));
    });
};
