import { Dialog } from 'react-md';
import { Wizard } from '../';
import './WizardDialog.scss';

export default function WizardDialog({ visible, onHide, title, data }) {
  return (
    <Dialog
      id="wizard-dialog"
      className="focus-dialog"
      visible={visible}
      type="full-page"
      aria-labelledby="wizard-dialog-title"
      onRequestClose={onHide}
    >
      <Wizard
        data={data}
        title={title}
        hideStepWizard={onHide}
      />
    </Dialog>
  );
}
