import './ProgressBar.scss';

export default function ProgressBar({
  step,
  steps
}) {
  return (
    <div className="progress-container" >
      <div className="progress-indicator" style={{ width: `${(step / steps) * 100}%` }} />
    </div>
  );
}
