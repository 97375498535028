import { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SessionDialog } from '@parkhub/parkhub-ui';
import { ErrorModal, Layout } from '..';
import { getSessionTime, refreshSession, checkAuth, signOut, redirectToSignIn } from '../../utils';
import './App.scss';

function App() {
  const checkTime = useRef(false);
  const location = useLocation();
  const { pathname = '' } = location;
  const [sessionModalVisible, setSessionModalVisible] = useState(false);

  const hideDialog = () => setSessionModalVisible(false);
  const showDialog = () => setSessionModalVisible(true);

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);

          const checkSessionTimeout = () => {
            if (!checkAuth()) {
              signOut();

            } else {
              const minutes = getSessionTime();

              if (minutes > 1440) {
                hideDialog();
                signOut('?expired=true');

              } else if (minutes > 1438 && minutes < 1440) {
                showDialog();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        });
    }, [],
  );

  const checkLoggedIn = useCallback(() => {
    if (!checkAuth()) {
      redirectToSignIn();
    } else {
      startTimeout();
    }
  }, [startTimeout]);

  useEffect(() => {
    checkLoggedIn();

    return () => clearInterval(checkTime.current);
  }, [checkLoggedIn, pathname]);

  return (
    <>
      <Layout />
      <ErrorModal />
      <SessionDialog
        onHide={hideDialog}
        visible={sessionModalVisible}
        onContinue={startTimeout}
        onTimeExpire={() => signOut('?expired=true')}
        onSignOut={signOut}
      />
    </>
  );
};

export default App;
