import { useEffect } from 'react';
import { Button, GroupAddSVGIcon, TextIconSpacing } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { FilterChip, FilterBar, SearchInput } from '..';
import { FUSE_OPTIONS, isNotEmpty, checkForAccess } from '../../utils';
import { applyPeopleFilter, applyPeopleSearch, clearAllFilters, clearSearchTerm, setClientFilter, setTeamFilter } from '../../redux/slices/filter.slice';
import { getOrganizations } from '../../redux/slices/organizations.slice';

export default function PeopleTableActionBar({
  title,
  showStepWizard,
  resetSort
}) {
  const dispatch = useDispatch();
  const clientFilter = useSelector(state => state.filter.clientFilter);
  const teamFilter = useSelector(state => state.filter.teamFilter);
  const people = useSelector(state => state.users.data);
  const organizations = useSelector(state => state.organizations.data);

  useEffect(() => {
    if (!isNotEmpty(organizations)) {
      dispatch(getOrganizations());
    }
  }, [organizations, dispatch]);

  const applyFilter = (filterArr, filterKey) => {
    const filteredPeopleData = [];

    people.forEach(x => {
      if (x.relationships && x.relationships.some(r => filterArr.includes(r.fromPartyKey))) {
        filteredPeopleData.push(x);
      }
    });

    if (filterKey === 'clientIdFilter') {
      dispatch(setTeamFilter(null));

    } else if (filterKey === 'teamIdFilter') {
      dispatch(setClientFilter(null));
    }

    dispatch(clearSearchTerm());
    return dispatch(applyPeopleFilter(filteredPeopleData));
  };

  const removeFilter = () => {
    dispatch(applyPeopleFilter(null));
    dispatch(clearAllFilters());
  };

  const handleSearch = (term) => {
    const searchList = people;
    const fuse = new Fuse(searchList, FUSE_OPTIONS);
    const results = fuse.search(term).map(result => result.item);

    dispatch(applyPeopleSearch(results, term));
  };

  const addItem = () => {
    resetSort();
    removeFilter();
    showStepWizard({ title });
  };

  return (
    <div className="table-action-bar">
      <FilterBar>
        {
          isNotEmpty(clientFilter) &&
          <FilterChip
            label="Clients"
            filterKey="clientIdFilter"
            onApply={applyFilter}
            onRemove={removeFilter}
            initialData={clientFilter}
            setInitialData={options => dispatch(setClientFilter(options))}
          />
        }
        {isNotEmpty(teamFilter) &&
          <FilterChip
            label="Teams"
            filterKey="teamIdFilter"
            onApply={applyFilter}
            onRemove={removeFilter}
            initialData={teamFilter}
            setInitialData={options => dispatch(setTeamFilter(options))}
          />
        }
        <SearchInput
          id={`${title.toLowerCase()}-search-input`}
          handleSearch={handleSearch}
        />
      </FilterBar>
      <div className="add-item-container">
        {checkForAccess('suite.users.persons.create', 'WRITE') &&
          <Button
            id={`${title.toLowerCase()}-open-wizard`}
            className="add-item-button"
            onClick={addItem}
          >
            <TextIconSpacing icon={<GroupAddSVGIcon />}>
              create user
            </TextIconSpacing>
          </Button>
        }
      </div>
    </div>
  );
}
