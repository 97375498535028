import { useEffect, useState } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, TextField, ArrowBackSVGIcon } from 'react-md';
import { useDispatch } from 'react-redux';
import { ProgressBar } from '../../';
import { isNotEmpty } from '../../../utils';
import { refreshUsers } from '../../../redux/slices/users.slice';
import { getOrganizations } from '../../../redux/slices/organizations.slice';
import { clearOperatorData } from '../../../redux/slices/operators.slice';

const UserFullName = ({ editData = {}, data = {}, relationships, actions, title, hideStepWizard, currentStep, totalSteps, nextStep }) => {
  const dispatch = useDispatch();
  const { update } = actions;
  const [firstName, setFirstName] = useState(editData?.firstName || data?.firstName || '');
  const [lastName, setLastName] = useState(editData?.lastName || data?.lastName || '');
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (relationships.length < 1) {
      dispatch(getOrganizations());
    }
  }, [relationships, dispatch]);

  function validInput(name) {
    return name.match(new RegExp(/^[a-zA-Z'-\s]+$/)) && name.length > 0 && name.length < 50 ? true : false;
  }

  const handleFirstName = (name) => {
    setFirstName(name);
    setFormChanged(true);
  };

  const handleLastName = (name) => {
    setLastName(name);
    setFormChanged(true);
  };

  const handlePrevious = () => {
    dispatch(clearOperatorData());
    // refresh if a person has been created and the user decides to back out
    if (editData.created || editData.updatedRelationships) {
      dispatch(refreshUsers());
    }

    hideStepWizard();
  };

  const updateAndNavigate = () => {
    if (formChanged) {
      update({ firstName, lastName });
    }

    nextStep();
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={handlePrevious} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {isNotEmpty(data) ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={isNotEmpty(data) ? 4 : totalSteps} />
      <div className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">What’s their full name?</div>
          </div>
          <div className="wizard-text-multi-input-container">
            <TextField
              id="person-first-name-input"
              name="person-first-name-input"
              className="wizard-text-input"
              label="First Name"
              value={firstName}
              onChange={(e) => handleFirstName(e.target.value)}
              theme="outline"
            />
            <TextField
              id="person-last-name-input"
              name="person-last-name-input"
              className="wizard-text-input"
              label="Last Name"
              value={lastName}
              onChange={(e) => handleLastName(e.target.value)}
              theme="outline"
            />
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              disabled={!validInput(firstName) || !validInput(lastName)}
              onClick={updateAndNavigate}
              theme="primary"
              themeType="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserFullName;
