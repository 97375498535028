import { useState } from 'react';
import { ActiveAscending, ActiveDescending, InactiveSort } from '../../assets/react-icons';

export default function ThItem({
  onClick,
  sortKey,
  sortable,
  children,
  activeSortKey
}) {
  const [ascDirection, setAscDirection] = useState(true);

  const toggleSortDirection = () => {
    setAscDirection((prevState) => !prevState);
  };

  const handleClick = () => {
    const direction = ascDirection ? 'asc' : 'desc';

    onClick(sortKey, direction, sortable);
    toggleSortDirection();
  };

  return (
    <div className="th-item" onClick={handleClick}>
      <span>{children}</span>
      <span className="th-icon">
        {
          (activeSortKey === sortKey) ?
            ascDirection ?
              <ActiveAscending />
              :
              <ActiveDescending />
            : sortKey && <InactiveSort />
        }
      </span>
    </div>
  );
}
