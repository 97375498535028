import { PlaceIcon, PersonIcon, BuildingIcon } from '../../assets/react-icons';
import { capitalize } from 'lodash';
import './TreeContainer.scss';

const TreeNode = ({
  nodeDatum,
  toggleNode
}) => {
  // format the way the relationship type is displayed
  const formatRelationshipType = (relType) => {
    if (relType) {
      const words = relType.toLowerCase().split('_');

      const titleized = words.map(word => capitalize(word));

      return titleized.join(' ');
    }

    return '';
  };

  const getNodeIcon = ({ node }) => {
    if (!node) {
      return '';
    };

    if (node.type === 'ORGANIZATION' || node.name === 'Organizations') {
      return <BuildingIcon color="#EE702E" />;
    } else if (node.type === 'PERSON' || node.name === 'People') {
      return <PersonIcon color="#735EFC" />;
    } else if (node.type === 'LOT' || node.name === 'Lots') {
      return <PlaceIcon color="#57B361" />;
    }

    return '';
  };

  const nodeClassName = (nodeDatum && nodeDatum.type) ? `${nodeDatum.type.toLowerCase()}-node` : '';
  const icon = getNodeIcon({ node: nodeDatum });

  /* Must use underscored version of children here or else they aren't
   * until after you click. */
  const numChildren = nodeDatum._children ? nodeDatum._children.length : '';

  /* Folder nodes need a little bit of extra styling */
  const folderClassName = (nodeDatum && nodeDatum.type === 'FOLDER') ? 'folder-node-header' : '';
  let registrationStatusText;

  if (nodeDatum && nodeDatum.type === 'PERSON' && nodeDatum.attributes) {
    if (nodeDatum.attributes.registrations > 0) {
      registrationStatusText = 'Registered';
    } else if (nodeDatum.attributes.invitations > 0) {
      // TODO switch this to show the number of invitations the user has been sent. Right now the number isn't available.
      registrationStatusText = 'Invited';
    } else {
      registrationStatusText = 'Not Invited';
    }
  }

  /* Since we have the folder nodes, the direct users will actually be the
   * grandchildren of the organization node via the person folder node */
  let directUserCount = 0;

  if (nodeDatum.type === 'ORGANIZATION' && nodeDatum._children && nodeDatum._children.length > 0) {
    const folders = nodeDatum._children;
    /* Loop through the child folders to find the person typed child folder */
    folders.forEach(f => {
      if (f._children && f._children.length > 0 && f._children[0].type === 'PERSON') {
        /* Direct user count will be the length of this child array */
        directUserCount = f._children.length;
      }
    });
  }

  return (
    <div onClick={toggleNode} className={`tree-node ${nodeClassName}`}>
      <div className={`${folderClassName} node-header`}>
        <div className="node-icon-title">
          <div className="node-icon">{icon}</div>
          <h4>{nodeDatum.name}</h4>
        </div>
        {
          nodeDatum.type === 'FOLDER' &&
          <h4 className="child-count">
            <span>{numChildren}</span>
          </h4>
        }
      </div>
      {
        nodeDatum.type !== 'LOT' &&
        <h6 className="node-relationship-type">{formatRelationshipType(nodeDatum.relationshipType)}</h6>
      }

      {/* If it's a venue team, add the landmark name */}
      {
        nodeDatum.attributes &&
        nodeDatum.attributes.landmarkName &&
        <h6>{nodeDatum.attributes.landmarkName}</h6>
      }
      {
        nodeDatum.type === 'ORGANIZATION' &&
        nodeDatum.descendantCounts &&
        <div>
          <h6>{`${nodeDatum.descendantCounts.persons} Total Users (${directUserCount} Direct)`}</h6>
          <h6>{`${nodeDatum.descendantCounts.organizations} Sub Organizations`}</h6>
          <h6>{`${nodeDatum.descendantCounts.lots} Lots Managed`}</h6>
        </div>
      }
      {
        nodeDatum.type === 'PERSON' &&
        <h6>{registrationStatusText}</h6>
      }
    </div>
  );
};

export default TreeNode;
